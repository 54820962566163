import { memo, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import createTheme from "@mui/material/styles/createTheme";
import styled from "@mui/material/styles/styled";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import dayjs from "dayjs";
import { alpha } from "@mui/system/colorManipulator";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MaterialDesignContent, SnackbarProvider } from "notistack";

import Root from "./routes/Root";

import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "./styles/ngprogress.css";
import "./styles/static-scrollbar.css";
import "./styles/table.css";
import "./styles/global.css";

import "dayjs/locale/ru";
import isToday from "dayjs/plugin/isToday";
import calendar from "dayjs/plugin/calendar";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

import api from "core/api";
import { StoreProvider } from "store/StoreProvider";

dayjs.locale("ru");
dayjs.extend(isToday);
dayjs.extend(calendar);

function localStorageProvider() {
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });

  return map;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#7047ea",
    },
    secondary: {
      main: "#E7E6EA",
    },
    accent: {
      main: "#ffc133",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 8,
          textTransform: "none",
          flexShrink: 0,

          "&.MuiButton-fullWidth": {
            flexShrink: 1,
          },

          "&:focus": {
            boxShadow: `${alpha("#7047ea", 0.25)} 0 0 0 0.2rem`,
          },

          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 50,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            marginLeft: 2,
            marginBottom: 6,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginLeft: 2,
          marginBottom: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        }
      }
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 10,
          textTransform: "none",
          flexShrink: 0,

          "&.MuiButton-fullWidth": {
            flexShrink: 1,
          },

          "&:focus": {
            border: "1px solid #7047ea !important",
            outline: "1px solid #7047ea",
          },

          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          transition: "opacity 150ms",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, Lexend, sans-serif",
        },
        body2: {
          color: "#6A6A7C",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      // styleOverrides: {
      //   root: {
      //     boxShadow:
      //       "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      //   },
      // },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "12.5px 14px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ".MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0)",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#F1F0F4",
          padding: "24px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDrawer: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          transition: "none !important",
        },
      },
    },
  },
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  boxShadow: "none",

  "&.notistack-MuiContent-success": {
    backgroundColor: "#313131",
    color: "#fff",
    svg: {
      color: "#43a047",
    },
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#313131",
    color: "#fff",
    svg: {
      color: "#d32f2f",
    },
  },
}));

function App() {
  const options = useMemo(() => {
    return {
      // TODO: TurnOn
      provider: localStorageProvider,
      fetcher: (path, init) =>
        api
          .get(path, init)
          .then((res) => res.data)
          .then((axiosData) => axiosData.data),
      errorRetryCount: 0,
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      >
        <LocalizationProvider
          adapterLocale="ru"
          dateAdapter={AdapterDayjs}
          dateLibInstance={dayjs.utc}
        >
          <StoreProvider>
            <BrowserRouter>
              <SWRConfig value={options}>
                <Root />
              </SWRConfig>
            </BrowserRouter>
          </StoreProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default memo(App);
