import { memo, lazy, useMemo } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

const SignIn = lazy(() => import("pages/SignIn"));
const SignUp = lazy(() => import("pages/SignUp"));
const Home = lazy(() => import("pages/Home"));
const Orders = lazy(() => import("pages/Orders"));
const OrderDetails = lazy(() => import("pages/OrderDetails"));
const CookReport = lazy(() => import("pages/CookReport"));
const Customers = lazy(() => import("pages/Customers"));
const CustomerQR = lazy(() => import("pages/CustomerQR"));
const CustomerDeliveryItems = lazy(() => import("pages/CustomerDeliveryItems"));
const Users = lazy(() => import("pages/Users"));
const Products = lazy(() => import("pages/Products"));
const Reports = lazy(() => import("pages/Reports"));
const Menus = lazy(() => import("pages/Menus"));
const MenuDetails = lazy(() => import("pages/MenuDetails"));
const Leftovers = lazy(() => import("pages/Leftovers"));
const CustomersWithLeftovers = lazy(() => import("pages/CustomersWithLeftovers"));
const CustomerOrderProductsShort = lazy(() =>
  import("pages/CustomerOrderProductsShort")
);
const RequireAuth = lazy(() => import("./RequireAuth"));
const RequireNotAuth = lazy(() => import("./RequireNotAuth"));
const AuthLayout = lazy(() => import("./AuthLayout"));
const DashboardLayout = lazy(() => import("./DashboardLayout"));

function Root() {
  let location = useLocation();

  const redirectState = useMemo(() => {
    return { from: location };
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/private" state={redirectState} replace />}
      />
      <Route
        path="/sign-in"
        element={
          <RequireNotAuth>
            <AuthLayout />
          </RequireNotAuth>
        }
      >
        <Route index element={<SignIn />} />
      </Route>
      <Route
        path="/sign-up/:invitationToken"
        element={
          <RequireNotAuth>
            <AuthLayout />
          </RequireNotAuth>
        }
      >
        <Route index element={<SignUp />} />
      </Route>
      <Route path="/qr/:customerShortId" element={<CustomerQR />} />
      <Route
        path="/c/:customerShortId"
        element={<CustomerOrderProductsShort />}
      />
      <Route
        path="/private"
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route index element={<Home />} />
        <Route
          path="customers/:customerShortId"
          element={<CustomerDeliveryItems />}
        />
        <Route path="cook" element={<CookReport />} />
        <Route path="orders" element={<Orders />} />
        <Route path="orders/:orderId" element={<OrderDetails />} />
        <Route path="customers" element={<Customers />} />
        <Route path="users" element={<Users />} />
        <Route path="users" element={<Users />} />
        <Route path="products" element={<Products />} />
        <Route path="reports" element={<Reports />} />
        <Route path="leftovers" element={<Leftovers />} />
        <Route
          path="customers-with-leftovers"
          element={<CustomersWithLeftovers />}
        />
        <Route path="menus" element={<Menus />} />
        <Route path="menus/:menuId" element={<MenuDetails />} />
      </Route>
    </Routes>
  );
}

export default memo(Root);
