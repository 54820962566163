import axios from "axios";
import { store } from "store/StoreProvider";
import authSlice from "store/auth";

import { BASE_URL } from "../config";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "@accessToken"
    )}`;
    return config;
  },
  (error) => {
    console.log("error: ", error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("@me");
      localStorage.removeItem("@accessToken");
      store.dispatch(authSlice.actions.logout());
    }

    return Promise.reject(error);
  }
);

export default api;
