import { createSlice } from "@reduxjs/toolkit";

const safeParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.log('error: ', error);
    return null;
  }
}

const initialState = {
  accessToken: localStorage.getItem("@accessToken"),
  me: safeParse(localStorage.getItem("@me")),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { accessToken, me } = action.payload;
      state.accessToken = accessToken;
      state.me = me;
    },
    logout: (state) => {
      state.accessToken = null;
      state.me = null;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export default authSlice;
