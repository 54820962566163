import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux'

import storeConfig from '../store'

export const store = configureStore(storeConfig);

export const StoreProvider = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)
